.product-summary {
  width: 100%;

  .info-summary {
    margin-left: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    gap: 0px 10px;
  }
}

.card {
  border: 3px solid black;
  padding: 5px;
  background-color: var(--dark-blue);
}

.card1 {
  background-color: #b624ff;
}

.card2 {
  background-color: #32963d;
}

.card3 {
  background-color: #c41849;
}

.card4 {
  background-color: #03a5fc;
}

@media only screen and (max-width:594px) {

  .info-summary {
    justify-content: center;
  }

}
@media only screen and (max-width:882px) {

  .info-summary {
    margin-top: 16px;
    }

}

@media only screen and (max-width:593px) {
  .product-summary .info-summary {
    margin-left: 0px
    }
}