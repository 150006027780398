.inputDetail {
    font-weight: 300;
    padding: 10px 5px;
    border: 1px solid #777;
    border-radius: 8px;
    margin-right: 5px;
}

.botaoInput {
    background-color: var(--dark-blue);
    color: white;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #777;
    border-radius: 8px;
    transition: .5s;

    &:hover {
        cursor: pointer;
    }
}

.botaoInput:hover {
    color: var(--dark-blue);
    background-color: #fff;
}

@media screen and (max-width: 600px) {
    .inputDetail {
        font-size: 6px;
    }

    .botaoInput {
        font-size: 8px;
    }

}