.button123 {
    background-color: var(--dark-blue);
    color: white;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #777;
    border-radius: 8px;
    transition: .5s;

    &:hover {
        cursor: pointer;
    }
}

.button123:hover {
    color: var(--dark-blue);
    background-color: #fff;
}