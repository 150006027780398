.change-password {
  display: flex;
  justify-content: space-around;

  .password-card {
    padding: 2rem 4rem;
    border-radius: 8px;
    background: var(--dark-blue);
    border: 3px solid #777;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  input[type="password"] {
    margin-bottom: 2rem;
    border-radius: 8px;
  }

  .seubotao {
    padding: 1rem 1rem;
    color: #fff;
    border-color: #fff;
    background: var(--dark-blue);
    border-radius: 8px;
    font-size: 17px;
    padding: 7px 84.5px;
  }

  .seubotao:hover {
    color: #333;
    background: #fff;
  }

  .--form-control>* {
    margin: 0px;
  }
}