.add-product {
  margin-bottom: 3rem;
  margin-left: 10px;


  form {
    display: flex;
    column-gap: 20px;
    flex-wrap: wrap;
    flex-flow: row wrap;
    gap: 3rem;
  }

  .inputs {
    // align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  form {

    label {
      padding: 4.4px 74px;
      display: block;
      font-size: 1.4rem;
      font-weight: 500;
      color: white;
    }

    input,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-weight: 300;
      padding: 1rem 10px;
      border: 1px solid #777;
      border-radius: 8px;
      margin-bottom: 20px;
    }
  }
}

.Select {
  font-weight: 300;
  padding: 1rem 10px;
  border: 1px solid #777;
  border-radius: 8px;
  margin-bottom: 20px;
}

.Select option {
  width: 150px;
  border: 1px solid gray;
  border-radius: 5px;
}

.image-show {
  display: block;
}

.editor {
  width: clamp(500px, 100%, 400px, );
  background-color: rgb(255, 255, 255);
  border: 1px solid #777;
  border-radius: 4px;
}

@media screen and (max-width: 856px) {
  .editor {
    max-width: 235px;
  }

  .ql-editor {
    min-height: 323px;
    max-height: 323px;
  }
}

// @media screen and (max-width: 741px) {
//   .editor {
//     max-width: 285px;
//   }
// }

@media screen and (max-width: 741px) {
  .editor {
    max-width: 235px;
  }
}




.--btns-primary-products {
  padding: 8.6px 93px;
  border-radius: 8px;
  font-size: 15px;
  color: #fff;
  border-color: #777;
  background: var(--dark-blue);
  border-radius: 8px;
}

.--btns-primary-products:hover {
  color: var(--dark-blue);
  background-color: #fff;
  font-size: 15px;
}

@media screen and (max-width: 856px) {
  .ql-toolbar {
    display: none;
  }

}

@media screen and (max-width: 594px) {
  .add-product {
    justify-content: center;
    max-width: max-content;
    margin-inline:    auto;
    justify-content: center;
} 

.add-product form {
  justify-content: center !important;
} 
}