
.product-detail {
  margin-bottom: 5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  p{
    color: #ccc;
  }

  .card {
    margin: 30px;
    align-items: baseline;
  }
  .detail {
    & > * {
      margin-bottom: 2rem;
      margin-left: 3rem;
      margin-right: 3rem;
    }
    .badge {
      background-color:#fff ;
      color: var(--dark-blue) ;
      padding-left: 2px;
      padding-right: 2px;
      border-radius: 8px;
    }
  }
}

.group {
  border: 1px solid var(--dark-blue);
  padding: 5px;
}

.description{
  color: #fff;
}

.color{
  background-color:var(--dark-blue);
}

.delete_detail{
  size: 17;
  color: red;
}

img{
  width: 100%;
  height: 300px;
}

.color{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.valorPayment{
  display: flex;
  column-gap: 6px;
}

.icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  > * {
    margin-right: 7px;
    cursor: pointer;
    vertical-align: middle;
    align-self: center;
  }
}

.dateDetail{
  vertical-align: -3px;
    font-size: 0.7em;
}

@media screen and (max-width: 600px) {
  .b_detail {
    font-size: 10px;
  }
 .p_detail {
    font-size: 10px;
  }

  .delete_detail{
    size: 13 ;
  }
  
  .h4_detail{
    font-size: 14px;
  }
}
